<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-n3">
        Atome, le chat chimiste, a produit une CCM. Toutefois, plutôt que de migrer vers le haut de
        la plaque, le composé s’est simplement dissout dans le solvant utilisé pour l’élution.
        Quelle erreur Atome et ses pattes maladroites ont-ils commise?
      </p>

      <v-radio-group v-model="inputs.question1Value" class="ml-6 mb-n6" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
          :label="option.text"
        >
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'uOttawa1321Prelab1Q9Fr',
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        question1Value: null,
      },
      options: [
        {text: 'Il n’a pas suffisamment mis de composé sur la plaque.', value: 'notEnoughCompound'},
        {text: 'Il a utilisé une plaque trop rayé dans le bas', value: 'scratchedPlate'},
        {
          text: 'Le solvent qu’il a choisi pour l’élution était trop polaire',
          value: 'eluentTooPolar',
        },
        {
          text: 'La ligne de base qu’il a dessiné était plus basse que le niveau du solvant.',
          value: 'baselineBelowSolvent',
        },
      ],
    };
  },
};
</script>
